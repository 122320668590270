import React from 'react';
import { Link } from 'react-router-dom';

const RegisterPage = () => {
    return (
        <p>register page</p>
    );
};

export default RegisterPage;
