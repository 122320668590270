import React from 'react';
import { Link } from 'react-router-dom';

const LoginPage = () => {
    return (
        <p>login page</p>
    );
};

export default LoginPage;
